import React from 'react';
import { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Multiselect } from 'multiselect-react-dropdown';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import { URLS } from './URLS';
import { default_project } from './UTILS'

import { AppNavBar } from './AppNavBar';

import { BuildSelect, BuildSelect2, BuildText, BuildCheckbox, BuildMultiSelect, BuildDateTimePicker } from './Inputs'
import { Typography } from '@material-ui/core';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';

import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    load_skeleton: {
        background: "#b0b0b0",
    },
    props_card: {
        background: '#eee',
        margin: '3px',
    },
    results: {
        /*paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),*/
        width: '100%',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    container: {
        alignContent: 'center',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 150,
    },
    button_general: {
        margin: theme.spacing(1),
        background: '#2196f3',
        color: '#ffffff',
        '&:hover': {
            background: '#1d72d2',
        }
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    table_cell: {
        border: '1px solid',
        borderColor: theme.palette.primary.dark,
        background: theme.palette.primary.main,
        padding: '3px',
    },
    table: {
        margin: theme.spacing(1),
        border: '1px solid',
        borderSpacing: '0px',
        width: '100%',
    },
    table_3d: {
        width: '100%',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        margin: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    file_input: {
        display: 'none',
    },
    bar: {
        fill: '#ff0000',
    },
    btn_group: {
        widht: '100%',
        textAlign: 'left',
        padding: '5px',
    },
    grey_placeholder: {
        background: '#ffffff',
        height: "100%"
    },
    orderableTH: {
        background: '#555555',
        color: '#ffffff',
    },
    orangeRow:{
        background: "#ccad10"
    },
    greenRow:{
        background: "#99e38a"
    },
    blueRow:{
        background: "#8ad7e3"
    }
}));

function ProjectForm({ user, lists, onSuccess, init_project = null, variant }) {
    const classes = useStyles();
    var preselected_type = {
        id: 999999,
        name: 'undefined',
    }

    if (user.role == 'RFP') {
        preselected_type = {
            id: 7,
            name: "STAGING"
        }
    }

    const [errors, setErrors] = useState({
        id: '',
        maconomy: '',
        sn: '',
        access_pro: '',
        office: '',
        client: '',
        num_country: '',
        project_name: '',
        status: '',
        eta_project: '',
        revised_eta: '',
        eta: '',
        eta_charts: '',
        eta_tabs: '',
        eta_cj: '',
        eta_dp: '',
        eta_FW: '',
        eta_fwsetup: '',
        eta_overlay: '',
        eta_pm: '',
        revised_eta: '',
        questions: '',
        type: '',
        extra_coverage: '',
        owner: '',
        pm_onshore: '',
        pm_offshore: '',
        owner_id: '',
        software: '',
        orion: '',
        archive: '',
        comments: '',
        created_on: '',
        subscribers: '',
        url: '',
        attention: '',
        sd_plus: '',
        quota_approved: '',
        link_delivery: '',
        zone: '',
        complexity_new: '',
        soSubType: '',
        general_type: '',
        complexity_notes: '',
        logs_url: '',
        folder_url: '',
        tags: '',
    })

    const [model_errors, setModelErrors] = useState({
        maconomy: '',
        office: '',
        access_pro: '',
        client: '',
        project_name: '',
        status: '',
        type: '',
        attention: '',
        general_type: '',
        similar_projects: [],

    })

    const [project, setProject] = useState(default_project);
    const [client, setClient] = useState(null);

    const [force_similar, setForceSimilar] = useState(false)

    const [similar, setSimilar] = useState([]);

    const [hard_server_errors, setHardServerErrors] = useState([]);
    const [open_alert, setOpenAlert] = useState(false);

    const [active_fields, setActiveFields] = useState([])
    const [required_fields, setRequiredFields] = useState([])

    if (!init_project) project.owner = user;

    var offices = lists.offices;
    var users = lists.users;
    var pms = lists.pms;
    var complexities = lists.complexities;
    var tags = lists.tags;
    var statuses = lists.statuses;
    var attentions = lists.attentions;
    var types = lists.types;
    var rev_eta_perc = [{id: 20, name: "20%"},{id: 40, name: "40%"},{id: 60, name: "60%"}];

    console.log({statuses: statuses});
    if (user.role == 'RFP') {
        types = lists.types.filter((a) => a.name == "STAGING");
    }
    var general_types = lists.general_types;
    var clients = [...lists.clients, preselected_type];

    
    useEffect(() => {
        var def_pr = {...default_project};
        if(user.region.id === 7){
            def_pr.type = {id: 6, name: 'SO NA'};
        }
        setProject({...def_pr});
        setClient({...preselected_type});
    }, [])

    useEffect(() => {
        handleValidate();
    }, [project])

    useEffect(() => {
        if (client && client.id != preselected_type.id) {
            fetch(URLS.aj_get_validation_for_client, {
                method: 'POST',
                body: JSON.stringify({
                    client_id: client.id,
                    region_id: user.region.id,
                })
            }).then(res => res.json()).then(response => {
                var required = [];
                var active = [];
                console.log(response)
                Object.keys(response.schema).forEach(a => {
                    if (response.schema[a].required) {
                        required.push(a)
                    }
                    if (response.schema[a].selected) {
                        active.push(a)
                    }
                })
                setActiveFields(active);
                setRequiredFields(required);
            })
        }

    }, [client])

    useEffect(() => {
        console.log({ got_new_init: init_project })
        if (init_project) {
            console.log("VALID INI PROJECT")
            var subscribers_correct_format = [];
            lists.users.forEach((u) => {
                if (init_project.subscribers.includes(u.id)) {
                    subscribers_correct_format.push(u);
                }
            })
            console.log({ "CORRECT SUBSCRIBERS": subscribers_correct_format })
            //init_project.subscribers = subscribers_correct_format;
            setProject(init_project);
            setClient(init_project.client);
        }
    }, [init_project])

    function handleValidate() {
        console.log("VALIDATING");
        console.log(project)
        var newErrors = errors;
        if (!project.project_name.length && active_fields.includes('project_name')) {
            newErrors['project_name'] = "Provide project name";
        }
        else {
            newErrors['project_name'] = "";
        }
        // FULL SERVICE ETA CHECK


        if (project.eta <= 0 && active_fields.includes('eta')) {
            newErrors['eta'] = "The ETA must be a positive number!";
        }
        else {
            if (project.eta <= 1 && active_fields.includes('eta') && [1,2,3].includes(project.type.id)) {
                newErrors['eta'] = "The ETA must be greater than 1!";
            }
            else {
                newErrors['eta'] = "";
            }
        }

        if (project.maconomy.length < 8 && active_fields.includes('maconomy')) {
            console.log({ mac_length: project.maconomy.length })
            newErrors['maconomy'] = "At least 8 digits!";
        }
        else {
            console.log('No MAC error');
            newErrors['maconomy'] = "";
        }
        if (project.sn.length != 6 && active_fields.includes('sn')) {
            newErrors['sn'] = "The SN usually consists of 6 digits!";
        }
        else {
            newErrors['sn'] = "";
        }
        if (project.access_pro.length != 6 && active_fields.includes('access_pro')) {
            newErrors['access_pro'] = "Enter an acess pro number or copy the maconomy";
        }
        else {
            newErrors['access_pro'] = "";
        }
        if (project.office.id == 999999 && active_fields.includes('office')) {
            newErrors['office'] = "Select a valid office!";
        }
        else {
            newErrors['office'] = "";
        }
        if (project.client.id == 999999 && active_fields.includes('client')) {
            newErrors['client'] = "Select a valid client!";
        }
        else {
            newErrors['client'] = "";
        }
        if (project.type.id == 999999 && active_fields.includes('type')) {
            newErrors['type'] = "Select a valid type!";
        }
        else {
            newErrors['type'] = "";
        }
        if (project.owner.id == 999999 && active_fields.includes('owner')) {
            newErrors['owner'] = "Select a valid owner!";
        }
        else {
            newErrors['owner'] = "";
        }
        if (project.status.id == 999999 && active_fields.includes('status')) {
            newErrors['status'] = "Select a valid status!";
        }
        else {
            newErrors['status'] = "";
        }
        if (project.general_type.id == 999999 && active_fields.includes('general_type')) {
            newErrors['general_type'] = "Select a valid general type!";
        }
        else {
            newErrors['general_type'] = "";
        }

        if (project.pm_onshore.id == 999999 && active_fields.includes('pm_onshore')) {
            newErrors['pm_onshore'] = "Select a valid onshore PM!";
        }
        else {
            newErrors['pm_onshore'] = "";
        }

        if (project.pm_offshore.id == 999999 && active_fields.includes('pm_offshore')) {
            newErrors['pm_offshore'] = "Select a valid offshore PM!";
        }
        else {
            newErrors['pm_offshore'] = "";
        }

        console.log(newErrors);
        setErrors(newErrors);
    }

    function setInput(val, json_name) {
        console.log(json_name);
        var newproject = project;
        if (!json_name.includes('.')) {
            console.log("setting ver value")
            newproject[json_name] = val;
        } else {
            var res = json_name.split('.');
            newproject[res[0]][res[1]] = val;
        }
        newproject['unsaved'] = true;
        setProject(newproject);
        handleValidate();
        console.log(newproject);
    }

    function handleClientChange(val, json_name, list) {
        console.log({ val: val, json_name: json_name })
        var elem = list.filter(e => e.id === val)[0]
        var selectedText = elem.name;
        console.log(selectedText);
        var newproject = project;
        if (!json_name.includes('.')) {
            newproject[json_name] = { id: val, name: selectedText };

        } else {
            var res = json_name.split('.');
            newproject[res[0]][res[1]] = { id: val, name: selectedText };
        }

        newproject['unsaved'] = true;
        console.log(newproject)
        setProject(newproject);
        handleValidate();
        setClient({ id: val, name: selectedText })
    }

    function handleSelectChange(val, json_name, list) {
        console.log({ val: val, json_name: json_name })
        var elem = list.filter(e => e.id === val)[0]
        var selectedText = elem.name;
        console.log(selectedText);
        var newproject = project;

        if (!json_name.includes('.')) {
            newproject[json_name] = { id: val, name: selectedText };

        } else {
            var res = json_name.split('.');
            newproject[res[0]][res[1]] = { id: val, name: selectedText };
        }

        newproject['unsaved'] = true;
        console.log(newproject)
        setProject(newproject);
        handleValidate();
    }

    function handleCheckboxChange(val, json_name) {
        var newproject = project;
        if (!json_name.includes('.')) {
            newproject[json_name] = val;
        } else {
            var res = json_name.split('.');
            newproject[res[0]][res[1]] = val;
        }
        newproject['unsaved'] = true;
        setProject(newproject);
        handleValidate();
    }

    function handleMultiChange(val, json_name) {
        var newproject = project;
        if (!json_name.includes('.')) {
            newproject[json_name] = val;
        } else {
            var res = json_name.split('.');
            newproject[res[0]][res[1]] = val;
        }
        newproject['unsaved'] = true;
        console.log(newproject);
        setProject(newproject);
        handleValidate();
    }

    function handleNewDateTime(val, json_name) {
        var newproject = project;
        console.log({ dateval: val })
        var d = new Date(val);
        var date = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
        var time = d.getHours() + ":" + d.getMinutes()
        console.log(date);
        console.log(time);
        console.log(json_name);
        if (!json_name.includes('.')) {
            newproject[json_name]['date'] = date;
            newproject[json_name]['time'] = time;
        } else {
            var res = json_name.split('.');
            newproject[res[0]][res[1]]['date'] = date;
            newproject[res[0]][res[1]]['time'] = time;
        }
        newproject['unsaved'] = true;
        setProject(newproject);
        console.log(project);
        handleValidate();
    }

    function handleSubmit(e) {
        var prj = project;


        console.log(prj);

        console.log({rev: prj.revised_eta, rev_dd: prj.revised_eta_dd, rev_ver: prj.revised_eta_ver, rev_ver_dd: prj.revised_eta_ver_dd, eta: prj.eta, eta_ver: prj.eta_ver});
        
        if(prj.eta){
            if(prj.revised_eta_dd){
                let perc = prj.revised_eta_dd.id;
                let r = Math.ceil(parseInt(prj.eta)*perc/100);
                prj.revised_eta = r
            }
        }

        if(prj.eta_ver){
            if(prj.revised_eta_ver_dd){
                let perc = prj.revised_eta_ver_dd.id;
                let r = Math.ceil(parseInt(prj.eta_ver)*perc/100);
                prj.revised_eta_ver = r
            }
        }

        console.log({rev: prj.revised_eta, rev_dd: prj.revised_eta_dd, rev_ver: prj.revised_eta_ver, rev_ver_dd: prj.revised_eta_ver_dd, eta: prj.eta, eta_ver: prj.eta_ver});
       

        var success = false;
        var url = '';
        switch (variant) {
            case 'add':
                url = URLS.aj_create_project;
                break;
            case 'copy':
                    url = URLS.aj_create_project;
                    break;
            case 'edit':
                //url = URLS.aj_edit_project
                url = URLS.aj_create_project;
                break;
            case 'sub':
                url = URLS.aj_create_project;
                break;
            default:
        }


        fetch(url, {
            method: "POST",
            body: JSON.stringify({
                project: prj,
                force_similar: force_similar,
                user: user,
                sub: variant == 'sub',
                main_id: variant == 'sub' ? init_project.id : null,
                operation: variant,
            })
        }).then(res => res.json()).then((result) => {
            
            var gotprj = result.project?JSON.parse(result.project):'';
            var hard_error = result.hard_error;

            console.log(result);

            if (hard_error) {
                if (Object.keys(hard_error).length != 0) {
                    if (hard_error.similar_projects) {
                        
                        console.log(hard_error.similar_projects)
                        var new_similar = hard_error.similar_projects.map((s) => {
                            return (JSON.parse(s))
                        })

                        setSimilar(new_similar)
                    } else {
                        var error_texts = hard_error;
                        setHardServerErrors(error_texts);
                        setOpenAlert(true);
                    }
                } else {
                    onSuccess(gotprj);
                    setProject(default_project);
                }
            } else {
                onSuccess(gotprj);
                setProject(default_project);
            }

        },

            (error) => {
                console.log(error);
            })
    }

    function checkError(json_name) {
        handleValidate();
        return errors[json_name]?errors[json_name]:""
    }

    function handleForceSimilarChange() {
        setForceSimilar(!force_similar)
    }


    function getSteps() {
        return ['Client Selection' + (project.client.name ? (" || " + project.client.name) : ""), 'Required Fields'];
    }


    const name_to_component_map = {
        client: <BuildSelect2 json_name='client' label='Client' list={clients} init_value={project['client']} getError={checkError} onChange={handleClientChange} />,
        maconomy: <BuildText json_name='maconomy' active={active_fields.includes('maconomy')} label="Maconomy" getError={checkError} onChange={setInput} init_value={project['maconomy']} />,
        sn: <BuildText active={active_fields.includes('sn')} json_name='sn' label="SN" getError={checkError} helper="Usually 6 digits" onChange={setInput} init_value={project['sn']} />,
        access_pro: <BuildText json_name='access_pro' active={active_fields.includes('access_pro')} label="Access Pro" getError={checkError} onChange={setInput} init_value={project['access_pro']} />,
        office: <BuildSelect2 active={active_fields.includes('office')} json_name='office' label='Office' list={offices} init_value={project['office']} getError={checkError} onChange={handleSelectChange} />,
        type: <BuildSelect2 active={active_fields.includes('type')} json_name='type' label='Type' list={types} init_value={project['type']} getError={checkError} onChange={handleSelectChange} />,
        project_name: <BuildText active={active_fields.includes('project_name')} json_name='project_name' label="Project Name" getError={checkError} onChange={setInput} init_value={project['project_name']} />,
        owner: <BuildSelect2 active={active_fields.includes('owner')} json_name='owner' label='Owner' list={users} init_value={project['owner']} getError={checkError} onChange={handleSelectChange} />,
        status: <BuildSelect2 active={active_fields.includes('status')} json_name='status' label='Status' list={statuses} init_value={project['status']} getError={checkError} onChange={handleSelectChange} />,
        num_country: <BuildText json_name='num_country' label="Number of countries" getError={checkError} onChange={setInput} init_value={project['num_country']} active={active_fields.includes('num_country')} />,
        general_type: <BuildSelect2 active={active_fields.includes('general_type')} json_name='general_type' label='General Type' list={general_types} init_value={project['general_type']} getError={checkError} onChange={handleSelectChange} />,
        pm_onshore: <BuildSelect2 active={active_fields.includes('pm_onshore')} json_name='pm_onshore' label='PM Onshore' list={pms} init_value={project['pm_onshore']} getError={checkError} onChange={handleSelectChange} />,
        pm_offshore: <BuildSelect2 active={active_fields.includes('pm_offshore')} json_name='pm_offshore' label='PM Offshore' list={pms} init_value={project['pm_offshore']} getError={checkError} onChange={handleSelectChange} />,

        eta_project: <BuildText json_name='eta_project' label="Project ETA" getError={checkError} onChange={setInput} init_value={project['eta_project']} active={active_fields.includes('eta_project')} />,
        revised_eta: <BuildText json_name='revised_eta' label="ETA Changes" getError={checkError} onChange={setInput} init_value={project['revised_eta']} active={active_fields.includes('revised_eta')} />,
        revised_eta_dd: <BuildSelect2 json_name='revised_eta_dd' list={rev_eta_perc} label="% Changes" getError={checkError} onChange={handleSelectChange} init_value={null} active={active_fields.includes('revised_eta')} />,
        
        eta: <BuildText json_name='eta' label="ETA Scripting" getError={checkError} onChange={setInput} init_value={project['eta']} active={active_fields.includes('eta')} />,
        eta_tabs: <BuildText json_name='eta_tabs' label="ETA Tabs" getError={checkError} onChange={setInput} init_value={project['eta_tabs']} active={active_fields.includes('eta_tabs')} />,
        eta_cj: <BuildText json_name='eta_cj' label="ETA CJ" getError={checkError} onChange={setInput} init_value={project['eta_cj']} active={active_fields.includes('eta_cj')} />,
        eta_dp: <BuildText json_name='eta_dp' label="ETA DP" getError={checkError} onChange={setInput} init_value={project['eta_dp']} active={active_fields.includes('eta_dp')} />,
        eta_fw: <BuildText json_name='eta_FW' label="ETA FW" getError={checkError} onChange={setInput} init_value={project['eta_FW']} active={active_fields.includes('eta_FW')} />,
        eta_fwsetup: <BuildText json_name='eta_fwsetup' label="ETA FW setup" getError={checkError} onChange={setInput} init_value={project['eta_fwsetup']} active={active_fields.includes('eta_fwsetup')} />,
        eta_overlay: <BuildText json_name='eta_overlay' label="ETA Overlay" getError={checkError} onChange={setInput} init_value={project['eta_overlay']} active={active_fields.includes('eta_overlay')} />,
        eta_pm: <BuildText json_name='eta_pm' label="ETA PM" getError={checkError} onChange={setInput} init_value={project['eta_pm']} active={active_fields.includes('eta_pm')} />,
        eta_charts: <BuildText json_name='eta_charts' label="ETA Charts" getError={checkError} onChange={setInput} init_value={project['eta_charts']} active={active_fields.includes('eta_charts')} />,
        eta_da: <BuildText json_name='eta_da' label="ETA Data Analyst" getError={checkError} onChange={setInput} init_value={project['eta_da']} active={active_fields.includes('eta_da')} />,
        eta_trm: <BuildText json_name='eta_trm' label="ETA Translation Management" getError={checkError} onChange={setInput} init_value={project['eta_trm']} active={active_fields.includes('eta_trm')} />,

        eta_project_ver: <BuildText json_name='eta_project_ver' label="Project ETA" getError={checkError} onChange={setInput} init_value={project['eta_project_ver']} active={active_fields.includes('eta_project_ver')} />,
        revised_eta_ver: <BuildText json_name='revised_eta_ver' label="ETA Changes" getError={checkError} onChange={setInput} init_value={project['revised_eta_ver']} active={active_fields.includes('revised_eta_ver')} />,
        revised_eta_ver_dd: <BuildSelect2 json_name='revised_eta_ver_dd' list={rev_eta_perc} label="% Changes" getError={checkError} onChange={handleSelectChange} init_value={null} active={active_fields.includes('revised_eta_ver')} />,

        eta_ver: <BuildText json_name='eta_ver' label="ETA Scripting" getError={checkError} onChange={setInput} init_value={project['eta_ver']} active={active_fields.includes('eta_ver')} />,
        eta_tabs_ver: <BuildText json_name='eta_tabs_ver' label="ETA Tabs" getError={checkError} onChange={setInput} init_value={project['eta_tabs_ver']} active={active_fields.includes('eta_tabs_ver')} />,
        eta_cj_ver: <BuildText json_name='eta_cj_ver' label="ETA CJ" getError={checkError} onChange={setInput} init_value={project['eta_cj_ver']} active={active_fields.includes('eta_cj_ver')} />,
        eta_dp_ver: <BuildText json_name='eta_dp_ver' label="ETA DP" getError={checkError} onChange={setInput} init_value={project['eta_dp_ver']} active={active_fields.includes('eta_dp_ver')} />,
        eta_fw_ver: <BuildText json_name='eta_FW_ver' label="ETA FW" getError={checkError} onChange={setInput} init_value={project['eta_FW_ver']} active={active_fields.includes('eta_FW_ver')} />,
        eta_fwsetup_ver: <BuildText json_name='eta_fwsetup_ver' label="ETA FW setup" getError={checkError} onChange={setInput} init_value={project['eta_fwsetup_ver']} active={active_fields.includes('eta_fwsetup_ver')} />,
        eta_overlay_ver: <BuildText json_name='eta_overlay_ver' label="ETA Overlay" getError={checkError} onChange={setInput} init_value={project['eta_overlay_ver']} active={active_fields.includes('eta_overlay_ver')} />,
        eta_pm_ver: <BuildText json_name='eta_pm_ver' label="ETA PM" getError={checkError} onChange={setInput} init_value={project['eta_pm_ver']} active={active_fields.includes('eta_pm_ver')} />,
        eta_charts_ver: <BuildText json_name='eta_charts_ver' label="ETA Charts" getError={checkError} onChange={setInput} init_value={project['eta_charts_ver']} active={active_fields.includes('eta_charts_ver')} />,
        eta_da_ver: <BuildText json_name='eta_da_ver' label="ETA Data Analyst" getError={checkError} onChange={setInput} init_value={project['eta_da_ver']} active={active_fields.includes('eta_da_ver')} />,
        eta_trm_ver: <BuildText json_name='eta_trm_ver' label="ETA Translation Management" getError={checkError} onChange={setInput} init_value={project['eta_trm_ver']} active={active_fields.includes('eta_trm_ver')} />,
        

        questions: <BuildText json_name='questions' label="Questions" getError={checkError} onChange={setInput} init_value={project['questions']} active={active_fields.includes('questions')} />,
        attention: <BuildSelect2 active={active_fields.includes('attention')} json_name='attention' label='Attention' list={attentions} init_value={project['attention']} getError={checkError} onChange={handleSelectChange} />,
        sd_plus: <BuildText json_name='sd_plus' active={active_fields.includes('sd_plus')} label="SD+" getError={checkError} onChange={setInput} init_value={project['sd_plus']} />,
        subscribers: <BuildMultiSelect active={active_fields.includes('subscribers')} json_name="subscribers" label="Subscribers" init_value={project.subscribers} getError={checkError} list={users} onChange={handleMultiChange} />,
        complexity_new: <BuildSelect2 active={active_fields.includes('complexity_new')} json_name='complexity_new' label='Complexity' list={complexities} init_value={project['complexity_new']} getError={checkError} onChange={handleSelectChange} />,
        complexity_notes: <BuildText json_name='complexity_notes' active={active_fields.includes('complexity_notes')} label="Complexity Notes" getError={checkError} onChange={setInput} init_value={project['complexity_notes']} />,
        url: <BuildText json_name='url' active={active_fields.includes('url')} label="URL" getError={checkError} onChange={setInput} init_value={project['url']} />,
        link_delivery: <BuildDateTimePicker active={active_fields.includes('link_delivery')} init_value={project.link_delivery.date + "T" + project.link_delivery.time + ":00"} label="Delivery" json_name='link_delivery' onChange={handleNewDateTime} />,
        zone: <BuildText active={active_fields.includes('zone')} json_name='zone' label="Zone" getError={checkError} onChange={setInput} init_value={project['zone']}/>,
        archive: <BuildCheckbox active={active_fields.includes('archive')} json_name="archive" label="Archived" init_value={project.archive} getError={checkError} onChange={handleCheckboxChange} />,
        quota_approved: <BuildCheckbox active={active_fields.includes('quota_approved')} json_name="quota_approved" label="Quota" init_value={project.quota_approved} getError={checkError} onChange={handleCheckboxChange} />,
        extra_coverage: <BuildCheckbox active={active_fields.includes('extra_coverage')} json_name="extra_coverage" label="Extra Coverage" init_value={project.extra_coverage} getError={checkError} onChange={handleCheckboxChange} />,
        orion: <BuildCheckbox active={active_fields.includes('orion')} json_name="orion" label="Orion" init_value={project.orion} getError={checkError} onChange={handleCheckboxChange} />,
        folder_url: <BuildText json_name='folder_url' label="Materials" getError={checkError} onChange={setInput} init_value={project['folder_url']} active={active_fields.includes('folder_url')} />,
        logs_url: <BuildText json_name='logs_url' label="Logs" getError={checkError} onChange={setInput} init_value={project['logs_url']} active={active_fields.includes('logs_url')} />,
        tags: <BuildMultiSelect active={active_fields.includes('tags')} json_name="tags" label="TAGS" init_value={project.tags} getError={checkError} list={tags} onChange={handleMultiChange} />,
    }

    function getStepContent(step) {
        switch (step) {
            case 0:
                return name_to_component_map.client
            case 1:
                return (
                    <React.Fragment>
                        <Grid container spacing={2}>
                            <Grid item xl={6} md={6} sm={12}>
                                <Card>
                                    <CardContent>
                                        <Typography variant="h5">Main Info</Typography>
                                        {user.role !== 'RFP' &&
                                            <Grid container
                                                direction="row"
                                                justify="space-between"
                                                alignItems="stretch"
                                                spacing={2}
                                            >
                                                <Grid item xs={4}>
                                                    {name_to_component_map.maconomy}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {name_to_component_map.sn}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {name_to_component_map.access_pro}
                                                </Grid>
                                            </Grid>
                                        }
                                        <Grid container
                                            direction="row"
                                            justify="space-between"
                                            alignItems="stretch"
                                            spacing={2}
                                        >
                                            <Grid item xs={6}>
                                                {name_to_component_map.office}
                                            </Grid>
                                            <Grid item xs={6}>
                                                {name_to_component_map.type}
                                            </Grid>
                                        </Grid>


                                        <Grid container
                                            direction="row"
                                            justify="space-between"
                                            alignItems="stretch"
                                            spacing={2}
                                        >
                                            <Grid item xs={12}>
                                                {name_to_component_map.project_name}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {name_to_component_map.owner}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {name_to_component_map.status}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {name_to_component_map.num_country}
                                            </Grid>
                                        </Grid>


                                        <Grid container
                                            direction="row"
                                            justify="space-between"
                                            alignItems="stretch"
                                            spacing={2}
                                        >
                                            <Grid item xs={4}>
                                                {name_to_component_map.general_type}
                                            </Grid>
                                            {user.role !== 'RFP' &&
                                                <React.Fragment>
                                                    <Grid item xs={4}>
                                                        {name_to_component_map.pm_onshore}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        {name_to_component_map.pm_offshore}
                                                    </Grid>
                                                </React.Fragment>
                                            }
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xl={6} md={6} sm={12}>
                                <Card>
                                    <CardContent>
                                        <Typography variant="h5">Secondary Info</Typography>
                                        <Grid container
                                            direction="row"
                                            justify="space-between"
                                            alignItems="stretch"
                                            spacing={2}
                                        >

                                        <Grid item xs={6}>
                                            <Grid item xs={12}>
                                                BMR ESTIMATE
                                            </Grid>
                                            <Grid item xs={12} className={classes.orangeRow}>
                                                {name_to_component_map.eta_project}
                                            </Grid>
                                            <Grid item xs={12} className={classes.orangeRow}>
                                                {name_to_component_map.eta}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={6} md={6} className={classes.orangeRow}>
                                                        {name_to_component_map.revised_eta}
                                                    </Grid>
                                                    <Grid item xs={6} md={6} className={classes.orangeRow}>
                                                        {name_to_component_map.revised_eta_dd}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            
                                            <Grid item xs={12} className={classes.orangeRow}>
                                                {name_to_component_map.eta_overlay}
                                            </Grid>
                                            <Grid item xs={12} className={classes.orangeRow}>
                                                {name_to_component_map.eta_fwsetup}
                                            </Grid>

                                            <Grid item xs={12} className={classes.greenRow}>
                                                {name_to_component_map.eta_fw}
                                            </Grid>

                                            <Grid item xs={12} className={classes.blueRow}>
                                                {name_to_component_map.eta_dp}
                                            </Grid>
                                            <Grid item xs={12} className={classes.blueRow}>
                                                {name_to_component_map.eta_tabs}
                                            </Grid>
                                            <Grid item xs={12} className={classes.blueRow}>
                                                {name_to_component_map.eta_charts}
                                            </Grid>
                                            <Grid item xs={12} className={classes.blueRow}>
                                                {name_to_component_map.eta_da}
                                            </Grid>

                                            <Grid item xs={12}>
                                                {name_to_component_map.eta_trm}
                                            </Grid>
                                            <Grid item xs={12}>
                                                {name_to_component_map.eta_pm}
                                            </Grid>

                                            <Grid item xs={12}>
                                                {name_to_component_map.eta_cj}
                                            </Grid>
                                            
                                            
                                            
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Grid item xs={12}>
                                                VERIAN ESTIMATE
                                            </Grid>
                                            <Grid item xs={12} className={classes.orangeRow}>
                                                {name_to_component_map.eta_project_ver}
                                            </Grid>
                                            <Grid item xs={12} className={classes.orangeRow}>
                                                {name_to_component_map.eta_ver}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={6} md={6} className={classes.orangeRow}>
                                                        {name_to_component_map.revised_eta_ver}
                                                    </Grid>
                                                    <Grid item xs={6} md={6} className={classes.orangeRow}>
                                                        {name_to_component_map.revised_eta_ver_dd}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} className={classes.orangeRow}>
                                                {name_to_component_map.eta_overlay_ver}
                                            </Grid>
                                            <Grid item xs={12} className={classes.orangeRow}>
                                                {name_to_component_map.eta_fwsetup_ver}
                                            </Grid>

                                            <Grid item xs={12} className={classes.greenRow}>
                                                {name_to_component_map.eta_fw_ver}
                                            </Grid>

                                            <Grid item xs={12} className={classes.blueRow}>
                                                {name_to_component_map.eta_dp_ver}
                                            </Grid>
                                            <Grid item xs={12} className={classes.blueRow}>
                                                {name_to_component_map.eta_tabs_ver}
                                            </Grid>
                                            <Grid item xs={12} className={classes.blueRow}>
                                                {name_to_component_map.eta_charts_ver}
                                            </Grid>
                                            <Grid item xs={12} className={classes.blueRow}>
                                                {name_to_component_map.eta_da_ver}
                                            </Grid>

                                            <Grid item xs={12}>
                                                {name_to_component_map.eta_trm_ver}
                                            </Grid>
                                            <Grid item xs={12}>
                                                {name_to_component_map.eta_pm_ver}
                                            </Grid>

                                            <Grid item xs={12}>
                                                {name_to_component_map.eta_cj_ver}
                                            </Grid>
                                        </Grid>

                                            
                                            <Grid item xs={4}>
                                                {name_to_component_map.questions}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {name_to_component_map.attention}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {name_to_component_map.sd_plus}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {name_to_component_map.subscribers}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {name_to_component_map.complexity_new}
                                            </Grid>

                                            
                                            <Grid item xs={12}>
                                                {name_to_component_map.complexity_notes}
                                            </Grid>
                                            <Grid item xs={12}>
                                                {name_to_component_map.url}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {name_to_component_map.link_delivery}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {name_to_component_map.zone}
                                            </Grid>
                                            <Grid item xs={3}>
                                                {name_to_component_map.archive}
                                            </Grid>
                                            <Grid item xs={3}>
                                                {name_to_component_map.quota_approved}
                                            </Grid>
                                            <Grid item xs={3}>
                                                {name_to_component_map.extra_coverage}
                                            </Grid>
                                            <Grid item xs={3}>
                                                {name_to_component_map.orion}
                                            </Grid>
                                            <Grid item xs={12}>
                                                {name_to_component_map.folder_url}
                                            </Grid>
                                            <Grid item xs={12}>
                                                {name_to_component_map.logs_url}
                                            </Grid>
                                            <Grid item xs={12}>
                                                {name_to_component_map.tags}
                                            </Grid>
                                        </Grid>

                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xl={12} xs={12}>
                                <Button onClick={handleSubmit} className={classes.button_general}>
                                    SAVE!
                                        </Button>
                            </Grid>

                        </Grid>
                        <Grid container>
                            <Grid item xl={12}>
                                {hard_server_errors.length !== 0 &&
                                    <Snackbar open={open_alert} autoHideDuration={6000} onClose={() => setOpenAlert(false)}>
                                        <Alert onClose={() => setOpenAlert(false)} severity="error">
                                            {hard_server_errors.map((e, ind) => {
                                                return (
                                                    <div>{e.field} : {e.message}</div>
                                                )
                                            })}
                                        </Alert>


                                    </Snackbar>
                                }
                                {similar.length !== 0 &&
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={classes.orderableTH}>
                                                    MAC
                                                </TableCell>
                                                <TableCell className={classes.orderableTH}>
                                                    SN
                                                </TableCell>
                                                <TableCell className={classes.orderableTH}>
                                                    Access Pro
                                                </TableCell>
                                                <TableCell className={classes.orderableTH}>
                                                    Owner
                                                </TableCell>
                                                <TableCell className={classes.orderableTH}>
                                                    Type
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                        </TableBody>
                                        {similar.map((s, id) => {
                                            console.log(s);
                                            return (
                                                <TableRow>
                                                    <TableCell>
                                                        {s.maconomy}
                                                    </TableCell>
                                                    <TableCell>
                                                        {s.sn}
                                                    </TableCell>
                                                    <TableCell>
                                                        {s.access_pro}
                                                    </TableCell>
                                                    <TableCell>
                                                        {s.owner.name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {s.type.name}
                                                    </TableCell>
                                                </TableRow>
                                            )

                                        })}
                                    </Table>
                                }
                                {similar.length !== 0 &&
                                    <BuildCheckbox json_name="force_similar" label="Force Similar" init_value={force_similar} getError={checkError} onChange={handleForceSimilarChange} />
                                }

                            </Grid>
                        </Grid>
                    </React.Fragment>
                );
            default:
                return 'Unknown step';
        }
    }

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    return (
        <Paper>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                        <StepContent>
                            <Typography>{getStepContent(index)}</Typography>
                            <IconButton
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                color="inherit"
                                size="big"
                            >
                                <ArrowBackIosIcon />
                            </IconButton>



                            {(activeStep !== steps.length - 1) === true &&
                                <IconButton
                                    color="inherit"
                                    onClick={handleNext}
                                    size="big"
                                >
                                    <ArrowForwardIosIcon />
                                </IconButton>
                            }


                        </StepContent>
                    </Step>
                ))}
            </Stepper>


        </Paper>

    )

}

export { ProjectForm }